.lk-teach h3 {
  text-align: center;
}

.lk-teach .add-new,
.lk-teach .my-materilas {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.lk-teach .tag {
  color: black;
  text-decoration: none;
}
.lk-teach .tag:hover {
  background-color: transparent;
}

.lk-teach .grey-text {
  margin-top: 10px;
}

.lk-teach .class-cont {
  display: flex;
  flex-direction: column;
}
.lk-teach .class {
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  /* display: inline-block; */
  flex-shrink: 0;
  width: fit-content;
}
.lk-teach .class:hover {
  text-decoration: underline;
}

.lk-teach .create-class button {
  border-radius: 3px;
  margin-left: 5px;
}
