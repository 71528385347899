.App {
  height: 100%;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: column;
}
h1,
h2,
h3 {
  text-align: center;
}
button {
  font-family: inherit;
}
.tags {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px 4px;
  align-items: center;
  white-space: nowrap;

  color: rgb(0, 0, 0);
}
.tag {
  background-color: #eaeaea;
  padding: 3px 7px 3px 7px;
  border-radius: 10px;
  font-size: 0.75rem;
  border: 1px solid grey;
}

button {
  user-select: none;
  border: 1px solid grey;
  padding: 6px;
  border-radius: 1dvh;
}
button:hover,
summary:hover {
  cursor: pointer;
}
button:disabled {
  cursor: default;
}
button:active {
  background-color: white;
}

.black-button {
  background-color: rgb(57, 57, 57);
  color: white;
  border-style: solid;
  border-color: rgb(57, 57, 57);
}

.black-button:not(:disabled):active {
  color: rgb(0, 0, 0);
  background-color: #f5f5f5;
}
.black-button:disabled {
  color: grey;
  border-color: grey;
  background-color: transparent;
}
.red-button {
  background-color: #ff7f7f;
  border-style: solid;
  border-color: grey;
}

textarea {
  padding: 5px;
  font-size: 1rem;
}

.arrow,
.delete {
  font-size: 1.7rem;
  cursor: pointer;
  user-select: none;
  color: grey;
}
.arrow:hover,
.delete:hover {
  color: rgb(0, 0, 0);
}

.arrow:active {
  color: rgb(54, 255, 57);
}

.delete:active {
  color: red;
}

input,
select {
  user-select: none;
  outline: none;
  border: 1px solid grey;
  border-radius: 2px;
  padding: 5px;
}
input:focus {
  background-color: rgb(255, 246, 236);
}

button:disabled {
  pointer-events: none;
}

.active {
  text-decoration: underline;
}

.grey-text {
  color: grey;
  font-size: 14px;
}

.navigate-div {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
}
.navigate-el {
  cursor: pointer;
}

pre[class*="language-"] {
  background-color: #f7f7f7;
  border-radius: 5px;
  border: 1px solid rgb(206, 206, 206);
  margin: 20px;
  font-size: 13px;
}
.token.operator {
  background-color: transparent;
}

/* Table */
table {
  border-collapse: collapse;
}
table th {
  background-color: rgb(206, 206, 206);
  padding: 10px;
}
table td {
  padding: 8px;
}

table td table {
  margin: auto;
}
table td table td {
  padding: 2px;
  /* border: 0px; */
}
.table,
.table th,
.table td {
  border: 1px solid grey;
  text-align: center;
  vertical-align: middle;
}

.navigate-button {
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 0px;
  padding: 0px;
  font-size: 15px;
}
.navigate-button:hover {
  background-color: transparent;
}

.grey {
  color: grey;
}

.error {
  border-color: #ff7f7f;
}

.common-a {
  color: black;
  text-decoration: none;
}
.common-a:hover {
  text-decoration: underline;
}
