.content-container .exam-results {
  padding-top: 75px;
  padding-bottom: 50px;
}

.exam-results .title {
  display: inline-block;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
}

.exam-results .score-time-cont {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}
.exam-results .test-score,
.exam-results .time {
  display: inline-block;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 8px;
  background-color: white;
}
.exam-results .score {
  margin-top: 10px;
  margin-bottom: 10px;
}

.exam-results select {
  padding: 3px;
  border-radius: 5px;
  outline: none;
}

.exam-results .results-table-cont {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
  padding-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
}
.exam-results .results-table-cont table {
}

.exam-results .achievements {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 5px;
}

.exam-results .ach-item {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  background-color: rgb(132, 255, 130);
  cursor: pointer;
}

.exam-results .score-100 {
  background: linear-gradient(135deg, #ffd700, #ffc700, #ffb700, #ffe680);
  color: #4b3e00;
  box-shadow: 0 0 15px 4px rgba(255, 215, 0, 0.6);
  animation: gold-glow 2s ease-in-out infinite;
}

@keyframes gold-glow {
  0% {
    box-shadow: 0 0 10px 2px rgba(255, 215, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 20px 6px rgba(255, 215, 0, 0.9);
  }
  100% {
    box-shadow: 0 0 10px 2px rgba(255, 215, 0, 0.5);
  }
}
