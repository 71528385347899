.lk-messages {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 15px;
  padding-bottom: 20px;
}

.lk-messages .message {
  border: 1px solid grey;
  border-radius: 5px;
  overflow: hidden;
}

.lk-messages .message .message-header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: rgb(223, 223, 223);
}

.lk-messages .message .message-header {
  padding: 10px;
}
.lk-messages .message .message-content {
  background-color: white;
  padding: 15px;
}

.lk-messages-section .class-item {
  display: flex;
  align-items: end;
  gap: 8px;
  font-size: 16px;
}
.lk-messages-section .del-student {
  color: grey;
  padding: 0px;
  background-color: transparent;
  border: 0px;
  font-size: 15px;
}
.lk-messages-section .del-student:hover {
  color: red;
}
