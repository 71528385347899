.create-collection {
  text-align: left;
  margin: 0px;
  padding: 30px;
}
.create-collection > * {
  padding-bottom: 10px;
}
.create-collection .discr {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 90%;
}
.create-collection button {
  padding: 6px;
}

.create-collection .tasks-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 30px 0px 30px 0px;
}
.cc-task {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 10px;
  /* flex-wrap: wrap; */
  width: 70%;
  min-width: 300px;
}

.create-collection .add-by-id {
  display: flex;
  align-items: center;
  gap: 5px;
}
.create-collection .add-by-id input {
  width: 100px;
  font-size: 16px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.create-collection .task-container {
  margin: 0px;
  width: 100%;
}
.create-collection .task {
  margin: 0px;
  width: 100%;
}

.create-collection .delete {
  padding-right: 5px;
}

.create-collection .subject-select {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

@media (max-width: 600px) {
  .create-collection {
    padding-right: 15px;
    padding-left: 15px;
  }

  .cc-task {
    flex-direction: column;
    width: 95%;
  }
}
