.create-task {
  padding: 30px;
  padding-bottom: 0px;
  padding-top: 0px;
  text-align: left;
  /* margin-top: 15px; */
}

.create-task .task-id {
  text-align: center;
  font-size: 20px;
  padding-bottom: 10px;
  color: grey;
}
.create-task .tags {
  margin-bottom: 10px;
  gap: 8px;
}
.create-task .tags label {
  margin-right: 8px;
}
.create-task select {
  padding: 4px;
  outline-color: grey;
}

.create-task .tags .NC {
  color: rgb(255, 108, 108);
}
.create-task .tags option {
  color: black;
}

.create-task .editor-container {
  margin-top: 30px;
  margin-bottom: 30px;
}
.create-task .editor-label {
  color: grey;
  padding: 3px;
}
.create-task .answer-label {
  color: grey;
  margin-bottom: 5px;
}

.create-task .answer {
  margin-bottom: 10px;
}
.create-task select {
  margin-bottom: 5px;
}
.create-task .answer input {
  padding: 5px;
  font-size: 18px;
}
.create-task .save-button {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.create-task .editor {
  max-width: 80%;
}

.create-task .text-answer-explanation {
  color: grey;
}

.create-task .upload-files {
  margin-top: 30px;
  margin-bottom: 15px;
}
.create-task .upload-files .title {
  color: grey;
}

.create-task .upload-files .file-control {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}
.create-task .upload-files .file-input {
  border: 0px;
}
.create-task .upload-files .file-input:hover {
  cursor: pointer;
}
.create-task .upload-files .file-input:focus {
  background-color: transparent;
}

.create-task .upload-files .files-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.create-task .upload-files .file-item {
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  width: fit-content;
}
.create-task .upload-files .file-item a {
  color: black;
  text-decoration: none;
}
.create-task .upload-files .file-item button {
  background-color: transparent;
  color: red;
}
.create-task .upload-files .file-item a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .create-task .editor {
    max-width: 100%;
  }
}
