.content-container .course-container {
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  height: 100dvh;
}

.content-container .course-container {
  margin-top: 0px;
}
