.achievements-section .achievements {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 5px;
}

.achievements-section .ach-item {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  background-color: rgb(199, 252, 207);
  cursor: pointer;
}
