.coll-cat-container {
}

.coll-cat-container .col-list {
  margin: 10px 50px 0px 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.coll-cat-container .col-item {
  display: flex;
  gap: 5px;
  align-items: center;

  background-color: rgb(255, 255, 255);
  padding: 10px;
  border: 1px solid grey;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}

.coll-cat-container .col-item:hover {
  background-color: rgb(237, 237, 237);
}
