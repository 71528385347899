.lk {
  margin: auto;
  /* margin-top: 10px; */
  padding-bottom: 20px;
  width: 80%;
  text-align: left;
}
.lk h2 {
  text-align: center;
}
.lk .lk-navigate {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 15px;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.lk .lk-navigate .el {
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 0px;
  padding: 0px;
  font-size: 15px;
}

@media (max-width: 600px) {
  .lk {
    width: 90%;
  }
}
