.bank-filter {
  border-radius: 1dvh;

  text-align: left;
  margin: auto;
  margin-top: 20px;
  min-width: 300px;
}

.bank-filter .choise-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: start;
  margin-top: 10px;
}

.bank-filter .source,
.exam,
.subject {
  border-radius: 1dvh;
  background-color: white;
  border: 1px solid grey;
  padding: 10px;

  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}
.bank-filter .source,
.bank-filter .subject {
  flex: 1;
  gap: 15px 15px;
}
.bank-filter .navigate-button {
  font-size: 1rem;
}

.bank-filter .filter-row {
  border-radius: 1dvh;
  background-color: white;
  border: 1px solid grey;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: start;
  flex-wrap: wrap;
}

.bank-filter .active {
  text-decoration: underline;
}

.bank-filter span:hover {
  cursor: pointer;
}

.bank-filter .tag {
  border-radius: 0.5dvh;
  padding: 0px;
  margin-top: 6px;
  overflow: hidden;
  font-size: 1.5dvh;
}

.bank-filter .tag,
.bank-filter .filter-option {
  user-select: none;
}
.bank-filter .filter-option {
  background-color: rgb(245, 245, 245);
  background-color: transparent;
  color: grey;

  font-size: 0.8rem;
}
.bank-filter .option {
  text-align: center;
  padding: 5px 10px 5px 10px;
}
.bank-filter .tag .option {
  font-size: 0.9rem;
}
.bank-filter .tag .option:hover {
  cursor: pointer;
  background-color: rgb(134, 249, 161);
}

.bank-filter .option:nth-child(even) {
  background-color: rgb(223, 223, 223);
}

.bank-filter .tag .option.select {
  background-color: rgb(134, 249, 161);
}

.bank-filter .tag .option.clear {
  background-color: rgb(255, 172, 172);
}

.button-row {
  display: flex;

  justify-content: space-between;
  margin-top: 10px;
  text-align: right;
}

.button-row button {
  margin-left: auto;
}
