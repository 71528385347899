.input-with-but {
  display: flex;

  align-items: start;
  flex-wrap: wrap;
  grid-gap: 20px 15px;
  overflow: auto;
}

.task-answer input {
  padding: 5px;
  font-size: 1.1rem;
  width: 150px;
}

.task-answer input::placeholder {
  font-size: 0.8rem;
}

/* Text answer */
.task-answer .answer > input {
  border: 1px solid grey;
  border-radius: 4px;
}
.task-answer input:focus {
  background-color: rgb(255, 247, 234);
  outline: none;
  box-shadow: none;
}
.create-task .task-answer .text-answer input {
  width: 250px;
}

/* Choice answer */
.task-answer .ch-option {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 7px;
}
.task-answer .ch-option {
  display: flex;
}

.task-answer .ch-input {
  cursor: pointer;
  width: initial;
  margin-top: 2px;
}
.task-answer .ch-input:disabled {
  cursor: initial;
}
.task-answer .ch-option button {
  color: red;
  border-radius: 3px;
}

.task-answer .ch-option .option {
  white-space: pre-wrap;
}

/* Comp */
.comparison-answer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.comp-option {
  display: flex;
  align-items: center;
  /* gap: 10px; */
}
.comp-option hr {
  width: 30px;
  height: 1px;

  background-color: grey;
  /* background-color: black; */

  border: 0px;
}
.task-answer .option-left,
.task-answer .option-right {
  white-space: pre-wrap;
  border: 1px solid grey;
  padding: 8px;
  border-radius: 5px;
  background-color: white;
}
.task-answer .option-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.task-answer .up-down {
  cursor: pointer;
  font-size: 20px;
  padding-right: 2px;
  user-select: none;
}
.task-answer .up-down:hover {
  color: rgb(121, 167, 253);
}
.task-answer .up-down:active {
  color: red;
}

/* Sorting Answer */
.task-answer .sorting-answer .for-user {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.task-answer .sorting-answer .for-user .option {
  display: flex;
  gap: 15px;
  padding: 8px;
  border: 1px solid grey;
  border-radius: 5px;
  background-color: white;
}

@media (max-width: 600px) {
  .task-answer .comparison-answer textarea,
  .task-answer .comparison-answer .ch-input {
    width: 130px;
  }
}
