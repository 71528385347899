.collection-container {
  padding-top: 20px;
  padding-bottom: 30px;
}

.collection-container .play-container {
  text-align: center;
  /* padding-right: 50px; */
  margin-top: 15px;
  margin-bottom: 15px;
}
.collection-container .play {
  cursor: pointer;
  text-align: left;
  border: 1px solid grey;
  /* background-color: rgb(45, 45, 45); */
  border-radius: 5px;
  padding: 8px;
  user-select: none;
}

.collection-container .count-answers {
  font-size: 20px;
}

.loading {
  margin-top: 100px;
}
