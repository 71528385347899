.course-container .left-menu {
  display: flex;
  width: 250px;
  background-color: white;

  text-align: left;
  border-right: 1px solid grey;
}

.course-container .left-menu-content {
  overflow: auto;
  flex: 1;
}

.course-container .hide-menu,
.course-container .show-menu {
  padding: 5px;
  border: 0px;
  border-radius: 0px;
  margin: 0px;
}
.course-container .hide-menu {
  border-left: 1px solid grey;
}
.course-container .show-menu {
  border-right: 1px solid grey;
}
.course-container .left-menu .course-info {
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid grey;
}

.course-container .left-menu .module-title {
  background-color: #e2e2e2;
  padding: 5px;
  padding-left: 10px;
}

.course-container .left-menu .active-lesson {
  background-color: rgb(155, 178, 255);
}

.course-container .left-menu .lesson {
  cursor: pointer;
  /* padding-left: 30px; */
}

.course-container .navigate-button {
  border-radius: 0px;
  width: 100%;
  height: 100%;
  padding: 5px;
  padding-left: 30px;
  text-align: left;
}
