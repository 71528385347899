.menu-container {
  box-sizing: border-box;
  background-color: #393939;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 50px;
  height: 50px;
  max-height: 50px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-container .menu {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  min-height: 28px;
  overflow: auto;
}
.menu-container a {
  color: black;
  color: rgb(231, 231, 231);
  text-decoration: None;
  text-align: left;
  user-select: none;
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
}

.menu-container .active {
  text-underline-offset: 5px;
  text-decoration: underline;
  text-decoration-color: rgb(231, 231, 231);
  text-decoration-thickness: 1px;
}
.menu-container .nav-links {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* justify-content: center;
  align-items: flex-start;
  gap: 10px 20px; */
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.menu-but {
  display: none;
  cursor: pointer;
  padding: 0px;
  color: white;
  background-color: transparent;
  border: 0px;
}
.menu-but:active {
  color: rgb(108, 255, 108);
}
.menu-but:hover {
  background-color: transparent;
}
.menu-but svg {
  min-width: 1.5rem;
  min-height: 1.5rem;
  /* margin-top: 2px; */
}

@media (max-width: 600px) {
  .menu-container a {
    margin-left: 5px;
    margin-right: 5px;
  }

  .menu-but {
    display: flex;
  }

  .menu-container {
    height: initial;
  }

  .menu-container .closed {
    display: none;
  }

  .menu-container .menu {
    gap: 0px;
    flex: 1;
  }
  .menu-container .nav-links {
    flex: 1;
  }
}

@media (pointer: fine) {
  .menu-but:hover {
    color: rgb(108, 255, 108);
  }
}
