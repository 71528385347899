.gen-col {
  padding-bottom: 30px;
}

.gen-col .col-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.gen-col .col-info input {
  width: 300px;
}
.gen-col .col-info .discription {
  width: 300px;
  height: 50px;
}

.gen-col .exam-subject-select {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.gen-col-table {
  margin: auto;
  margin-bottom: 15px;
  /* margin: 15px; */
  border-collapse: collapse;
  border: 1px solid grey;
  width: auto;
  background-color: white;
}
.gen-col-table td,
.gen-col-table tr,
.gen-col-table th {
  border-collapse: collapse;
  border: 1px solid grey;
  padding: 5px;
  font-size: 12px;
  word-wrap: break-word;
  white-space: normal;
}

.gen-col-table select {
  border: 0px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  padding: 5px;
  width: fit-content;
}
.gen-col-table select:hover {
  /* background-color: white; */
  /* border-color: black; */
}

.gen-col-table .active-td {
  background-color: rgb(190, 254, 203);
}

.gen-col-table .count-input {
  text-align: center;
  width: 30px;
  border-radius: 0px;
  border: 0px;
  background-color: transparent;
}

.gen-col-table .count-but {
  /* text-align: center; */
  color: grey;
  font-size: 14px;
  padding: 0px;
  width: 10px;
  height: 20px;
  border-radius: 0px;
  border: 0px solid grey;
  background-color: transparent;
}
.gen-col-table .count-but:hover {
  color: red;
}
.gen-col-table .count-but:active {
  color: rgb(127, 47, 255);
}

@media (max-width: 600px) {
  .gen-col {
    padding-right: 15px;
    padding-left: 15px;
  }
}
