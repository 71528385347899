.results-table {
  border-collapse: collapse;
  border: 1px solid grey;
  max-width: 100%;
}

.results-table th {
  background-color: rgb(206, 206, 206);
  padding: 5px;
  border: 1px solid grey;
}
.results-table td {
  border: 1px solid grey;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
}

.results-table td table {
  margin: auto;
  border: 0px;
}
.results-table td table td {
  padding: 2px;
  border: 0px;
}

.results-table .OK {
  background-color: rgb(199, 252, 207);
}
.results-table .WA {
  background-color: #ff7f7f;
}
.results-table .PA {
  background-color: #fffea8;
}

.results-table .NA {
  color: grey;
}
