.main-container {
  height: 100%;
  max-height: 100%;
}

.content-container {
  box-sizing: border-box;
  /* padding-top: 50px; */
  height: 100%;
}

.content-container > div {
  padding-top: 60px;
}
