div.task-container > .task {
  background-color: #d9d9d9;
}

div.task-container {
  margin: 50px 0px 50px 0px;
}

.task {
  border-radius: 1dvh;
  width: 70%;
  text-align: left;
  margin: auto;
  border: 1px solid grey;
  /* min-width: 300px; */
  overflow: hidden;
}

.task .task-body p:not(:last-child) {
  margin: 0px;
  padding-bottom: 15px;
}

.task-container .task-header {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  grid-gap: 5px 15px;

  /* border-radius: 1dvh 1dvh 0dvh 0dvh; */
  padding: 10px;
}

.task-container .task-body {
  padding: 15px;
  /* padding-top: 0px; */
  background-color: white;
  border-bottom: 1px solid grey;
  border-top: 1px solid grey;
  overflow: auto;
  text-align: justify;
  font-size: 1rem;
}

.task-container .task-body ol {
  padding: 10px;
  padding-left: 25px;
}

.task-container .task-body table {
  width: 90%;
  border-collapse: collapse;
}
.task-container .task-body img {
  max-width: 90%;
  height: auto;
  display: block;
}

.task-container .task-footer .floor {
  border-top: 1px solid grey;
}
.floor .likes {
  display: flex;
  align-items: center;
  gap: 4px;
}
.floor .like,
.dislike {
  display: flex;
  align-items: center;
  gap: 1px;
  font-size: 0.8rem;
  color: grey;
}
.floor .like,
.dislike:hover {
  cursor: pointer;
}

.task-container .task-footer .floor .h-content {
  padding: 15px;
  border: 1px solid rgb(188, 188, 188);
  border-radius: 5px;
  margin-top: 10px;
}

.task-container .task-footer > .answer {
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px 20px;
  flex-wrap: wrap;
}

.task-container .task-footer .floor {
  /* display: flex; */
  /* justify-content: space-between; */
  padding: 10px;
}

.task-container .task-footer .author {
  color: grey;
  font-size: 0.8rem;
  white-space: nowrap;
  text-decoration: none;
}
.task-container .task-footer a:hover {
  text-decoration: underline;
}

.task-header .numberEGE {
  font-size: 1rem;
  white-space: nowrap;
}
.task-header .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  /* grid-gap: 5px 4px; */
  align-items: center;
  white-space: nowrap;

  color: rgb(0, 0, 0);
}
.task-header .header-tag {
  background-color: #eaeaea;
  padding: 3px 7px 3px 7px;
  border-radius: 10px;
  font-size: 0.75rem;
  border: 1px solid grey;
}

.stat {
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px 3px;
  align-items: start;
  align-content: flex-start;
}

/* Status */
div.task-container > .OK .answer {
  background-color: #9ced99;
}

div.task-container > .WA .answer {
  background-color: #ff7f7f;
}

div.task-container > .PA .answer {
  background-color: #fffea8;
}

div.task-container > .EXIST .answer {
  background-color: #68deff;
}

.task-container .task-footer .buttons > button {
  border: 1px solid grey;
  /* padding: 7.6px; */
}

.task-container .task-footer .buttons > button:active {
  background-color: rgb(200, 200, 200);
}

.task-container .task-footer .buttons > button:disabled {
  background-color: white;
  border: 1px solid grey;
}

.task-container .task-footer .buttons {
  display: flex;
  gap: 5px;
}

.task-container .task-footer .show-solution {
  cursor: pointer;
  user-select: none;
}

.task-container .files-cont {
  display: flex;
  flex-wrap: wrap;
  gap: 0px 8px;
  margin-top: 30px;
}

.task-container .files-list {
  display: flex;
  gap: 0px 8px;
  flex-wrap: wrap;
}
.task .task-body .files-cont .f-title {
  padding-bottom: 0px;
}
.task-container .files-list a {
  color: grey;
}
.task-container .files-list a:hover {
  color: black;
}

@media (max-width: 600px) {
  .task {
    width: 90%;
  }
}
