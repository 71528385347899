.course-container > .lesson-container {
  box-sizing: border-box;
  padding-top: 20px;
  flex: 1;
  overflow: auto;
}

.course-container > .lesson-container .lesson-name-cont {
  display: flex;
  gap: 10px 5px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.course-container .edit-but {
  color: grey;
  cursor: pointer;
}
.course-container .edit-but:hover {
  text-decoration: underline;
}

.course-container > .lesson-container .lesson-name {
  text-align: left;
  color: grey;
}

.course-container > .lesson-container .lesson {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  margin: auto;
}

.course-container > .lesson-container .save-text-section {
  text-align: left;
}

@media (max-width: 600px) {
  .course-container > .lesson-container .lesson {
    width: 90%;
  }
}
