* {
  margin: 0;
  padding: 0;
}

html,
body,
#root,
.App {
  margin: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #f5f5f5;
}
