.h-content {
  background-color: white;
  overflow: auto;
}

.h-content img {
  max-width: 90%;
  height: auto;
  display: block;
}
