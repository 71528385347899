.course-container .lesson .lesson-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.course-container .lesson .menu-el,
.course-container .lesson .menu-active-el {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  min-width: 35px;
  border-radius: 5px;
  background-color: rgb(217, 217, 217);

  cursor: pointer;
  border: 1px solid grey;

  font-size: 0.9rem;
}
.course-container .lesson .plus {
  background-color: rgb(138, 255, 140);
}
.course-container .lesson-menu > .OK {
  background-color: rgb(138, 255, 140);
}
.course-container .lesson-menu > .WA {
  background-color: rgb(255, 105, 105);
}
.course-container .lesson-menu > .PA {
  background-color: #fffea8;
}

.course-container .lesson .menu-active-el {
  background-color: rgb(155, 178, 255);
  text-decoration: underline;
}
