.bank {
  width: 80%;
  margin: auto;
  margin-top: 0px;
  padding-bottom: 30px;
}

.bank .task {
  margin: auto;
  width: 80%;
}

.tasks {
  padding-top: 0px;
}

.count-find {
  text-align: right;
}

@media (max-width: 600px) {
  .bank {
    width: 90%;
  }
  .bank .task {
    width: 95%;
  }
}
