.create-task-cont {
  padding-bottom: 20px;
}

.container {
  margin: 10px;
}
.container button {
  border: 1px solid grey;
  margin-right: 10px;
}
/* .container button:active {
  background-color: white;
} */

.tags {
  gap: 10px;
}

.answer {
  text-align: left;
}
