.teacher-materials {
  margin-top: 10px;
  padding-bottom: 20px;
}

.teacher-materials .return-but {
  margin-top: 10px;
}

.teacher-materials .col-cont {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;
}
.teacher-materials .collection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid grey;
  width: 70%;
  background-color: white;
  cursor: pointer;
  overflow: auto;
}

.teacher-materials .buttons {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: 5px;
}
.teacher-materials .collection button {
  background-color: white;
  font-size: 12px;
  padding: 3px;
  border-radius: 3px;
}
.teacher-materials .collection p {
  text-align: left;
}
.teacher-materials .collection button:hover {
  background-color: rgb(235, 235, 235);
}

@media (max-width: 600px) {
  .teacher-materials .collection {
    width: 85%;
  }
}
